import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {authContext} from '../../../adalConfig'
import {toast} from "react-toastify";
import jwtDecode from 'jwt-decode';

import RoleAwareComponent from '../../../components/elements/RoleAwareComponent';
import FinancialYear from "../../drawers/FinancialYear/FinancialYear";
import Input from "../../elements/Input/Input";
import FloatingCalculator from "../../elements/FloatingCalculator/FloatingCalculator";
import DepartmentFilter from "../../elements/DepartmentFilter/DepartmentFilter";

import './Main.css';


class Main extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			planningPeriod: "",
			currentFiscalYearPlanId: ""
		};

		this.handleCollapse = this.handleCollapse.bind(this);
		this.handlePeriodChange = this.handlePeriodChange.bind(this);
		this.submitNewPeriod = this.submitNewPeriod.bind(this);
	}

	handleCollapse() {
		const drawerExpanded = document.body.classList.contains('drawer-expanded') || document.body.classList.contains('drawer-expanded-narrow');
		if (drawerExpanded) {
			this.props.onCollapse();
			this.props.history.push(this.props.location.pathname); // clear any active url params
		}
	}

	static handleLogout() {
		localStorage.clear();
		authContext.logOut();
	}

	static scrollTop() {
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		const token = localStorage.getItem('adal.idtoken');
		const tokenDecoded = jwtDecode(token);

		if (tokenDecoded) this.setState({name: tokenDecoded.name});

		const currentFiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
		this.setState({
			planningPeriod: currentFiscalYearPlan.currentPeriod,
			currentFiscalYearPlanId: currentFiscalYearPlan._id
		});
	}

	handlePeriodChange(e, v) {
		this.setState({
			[e]: v,
			awaitChange: true
		});
		clearTimeout(this.timer);
		this.timer = setTimeout(this.submitNewPeriod, 3000);
	}

	submitNewPeriod() {
		const token = localStorage.getItem('adal.idtoken');
		if (!token) return;
		if (typeof this.state.planningPeriod !== 'string' || this.state.planningPeriod.length < 0) return;
		fetch(process.env.REACT_APP_API_URL + 'fiscal-year-plan/currentPeriod/' + this.state.currentFiscalYearPlanId, {
			method: 'post',
			headers: {
				'Authorization': token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({period: this.state.planningPeriod})
		}).then((response) => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.submitNewPlan()
				})
			} else if (response.ok) {
				return response.json()
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).catch((error) => {
			toast('Could not update current planning period.', {className: "notification error"});
		});
	}

	render() {
		const year = new Date().getFullYear();
		return (
			<section className="main" onClick={this.handleCollapse}>

				<header className="main-header">

					<RoleAwareComponent access={["admin", "manager"]}>
						<nav className="page-nav">
							<ul>
								<RoleAwareComponent access={["admin", "manager"]}>
									<li>
										<NavLink onClick={this.props.collapseDrawer} to="/pre-work/programs"
												 isActive={() => ["/pre-work/programs", "/pre-work/pos-kits"].includes(this.props.location.pathname)}
												 activeClassName="is-active">Pre-Work</NavLink>
									</li>
								</RoleAwareComponent>
								<li>
									<NavLink onClick={this.props.collapseDrawer} to="/planning"
											 activeClassName="is-active">Planning</NavLink>
								</li>
							</ul>
						</nav>
					</RoleAwareComponent>

					<span className="planning-period">
                <nav className="page-nav">
                    <ul>
                            <li>
                                <NavLink onClick={() => this.props.expandDrawer(<FinancialYear
									handleRefresh={() => {
										this.props.handleRefresh();
										this.props.handleTotalsRefresh();
										this.componentDidMount();
									}}
									handleCancel={this.props.onCollapse}
								/>)
								} to="#" activeClassName="is-active"
										 isActive={() => true}>{JSON.parse(localStorage.getItem('fiscalYearPlan')).fiscalYear}</NavLink>
                            </li>
                    </ul></nav></span>

					<RoleAwareComponent access={["admin"]}>
						<div className="input-wrapper small">
							<Input id="planningPeriod" onChange={this.handlePeriodChange}
								   value={this.state.planningPeriod} type="text" target="planningPeriod"/>
						</div>
					</RoleAwareComponent>
					<RoleAwareComponent access={["read", "write", "planner", "observer", "manager"]}>
						<span className="planning-ends">{this.state.planningPeriod}</span>
					</RoleAwareComponent>
					<span className="name-role">{this.state.name ? this.state.name : "n/a"}</span>
					<a onClick={() => Main.handleLogout()} className="logout">Logout</a>

				</header>

				<div className="main-body">
					<DepartmentFilter handleRefresh={() => {
						this.props.handleRefresh();
						this.props.handleTotalsRefresh();
					}}/>
					{this.props.children}
				</div>

				<footer className="main-footer">
					<hr className="stroke"/>
					<small className="copyright">&copy; {year ? year : "2018"} MKTG</small>
					<button onClick={Main.scrollTop} className="scroll-top"/>
				</footer>
				<FloatingCalculator/>
			</section>
		)
	}
}

export default withRouter(Main);
