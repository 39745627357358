import React, {Component} from 'react';
import _displayConfiguration from '../../../json/displayConfiguration.js';

import './FloatingCalculator.css';

class FloatingCalculator extends Component {
    constructor() {
        super();
        this.state = {
            hidden: true,
            calculatorHidden: true,
            selectedBrand: '',
            selectedChannel: '',
            selectedMarket: '',
            selectedProgram: '',
            programs: [],
            brands: [],
            campaigns: [],
            channels: [],
            markets: [],
            divisions: [],
            noPrograms: false,
            eventCount: 0,
            eventCost: '$0.00'
        };
        this.openCalculator = this.openCalculator.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fetchEventCosts = this.fetchEventCosts.bind(this);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {productGroupId: {name: productGroup}} = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        let shouldHide = !_displayConfiguration[productGroup].showCalculator;
        if (shouldHide !== this.state.hidden) {
            this.setState({hidden: shouldHide});
        }
    }

    componentDidMount() {
        const token = localStorage.getItem('adal.idtoken');
        if (!token) return;
        const {_id: FISCAL_YEAR_PLAN_ID} = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        fetch(process.env.REACT_APP_API_URL + `filter/${FISCAL_YEAR_PLAN_ID}/planning`, {
            method: 'get',
            headers: {
                'Authorization': token,
                'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(Promise.reject.bind(Promise));
            }
        }).then((json) => {
            this.setState({
                brands: json.brands,
                campaigns: json.campaigns,
                channels: json.channels,
                divisions: json.divisions,
                markets: json.markets
            });
        }).catch((err) => {
            console.log("err ===> ", err);
        });

    }

    componentDidUpdate(prevProps) {

    }

    openCalculator() {
        this.setState({
            calculatorHidden: !this.state.calculatorHidden
        });
    }

    handleChange(e) {
        const target = e.target.id;
        const value = e.target.value;
        this.setState({
            [target]: value
        },()=>{
            if (this.state.selectedBrand !== '' && this.state.selectedChannel !== '' &&  this.state.selectedMarket !== '') {
                    if (target === 'selectedBrand' || target === 'selectedChannel' || target === 'selectedMarket') {
                        this.fetchEventCosts();
                    }

            }
        });
    }


    fetchEventCosts() {
        this.setState({
            noPrograms: false,
            programs: []
        });
        const token = localStorage.getItem('adal.idtoken');
        if (!token) return;
        const {_id: FISCAL_YEAR_PLAN_ID} = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        fetch(process.env.REACT_APP_API_URL + 'programs?hasDirector=false&fiscalYearPlanId=' + FISCAL_YEAR_PLAN_ID + '&brandId=' + this.state.selectedBrand + '&marketId=' + this.state.selectedMarket + '&channelId=' + this.state.selectedChannel, {
            method: 'get',
            headers: {
                'Authorization': token,
                'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        }).then((json) => {
            this.setState({
                programs: json && json.docs ? this.getDistinctPrograms(json.docs) : [],
                noPrograms: json.docs.length <= 0
            });
        });
    }

    getDistinctPrograms(programs) {
        let names = programs.map(p => p.prettyName);
        return programs.filter((p, i) => names.indexOf(p.prettyName) === i);
    }

    render() {
        let eventCost;
        try {
            eventCost = this.state.selectedProgram !== '' ? '$' + (this.state.eventCount * this.state.programs.filter(p => p._id === this.state.selectedProgram)[0].monthItems[0].eventCost.division).toFixed(2) : '$0.00';
        }
        catch(e){
            eventCost = '$0.00';
        }
        return (
            <div hidden={this.state.hidden}>
                <div className='calculator-button' onClick={this.openCalculator}>
                    <div className='calculator-tooltip'><span>NEW!</span> Planning cost calculator</div>
                    <img src='../images/calculator.png' alt="Calculator icon"/>
                </div>
                <div className='calculator' data-hidden={this.state.calculatorHidden ? true : null}>
                    <label className="title">Please select a program below to calculate costs:</label>

                    <div className="custom-select">
                        <select id='selectedBrand' onChange={this.handleChange} value={this.state.selectedBrand}>
                            <option default value="">Select a Brand</option>
                            {this.state.brands && this.state.brands.sort(this.compare).map(function (brand, i) {
                                return (
                                    <option value={brand._id} key={i}>{brand.prettyName}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="custom-select">
                        <select id='selectedChannel' onChange={this.handleChange} value={this.state.selectedChannel}
                                disabled={this.state.selectedBrand === ''}>
                            <option default value="">Select a Channel</option>
                            {this.state.channels && this.state.channels.sort(this.compare).map(function (channel, i) {
                                return (
                                    <option value={channel._id} key={i}>{channel.prettyName}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="custom-select">
                        <select id='selectedMarket' onChange={this.handleChange} value={this.state.selectedMarket}
                                disabled={this.state.selectedChannel === ''}>
                            <option default value="">Select a Market</option>
                            {this.state.markets && this.state.markets.sort(this.compare).map(function (market, i) {
                                return (
                                    <option value={market._id} key={i}>{market.prettyName}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="custom-select">
                        <select id='selectedProgram' onChange={this.handleChange} value={this.state.selectedProgram}
                                disabled={this.state.selectedMarket === '' || this.state.programs.length <= 0}>
                            <option default
                                    value="">{this.state.programs.length <= 0 && this.state.selectedMarket !== '' ? (this.state.noPrograms ? 'No programs found':'Loading...') : 'Select a Program'}</option>
                            {this.state.programs && this.state.programs.sort(this.compare).map(function (program, i) {
                                return (
                                    <option value={program._id} key={i}>{program.prettyName}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="calculate-form" disabled={this.state.selectedProgram === ''}>
                        <div className='field'>
                            <label># of events</label>
                            <input type="text" id='eventCount' value={this.state.eventCount} onChange={this.handleChange} disabled={this.state.selectedProgram === ''}/>
                        </div>
                        <div className='field'>
                            <label>Total cost</label>

                            <input type="text" value={eventCost} disabled={true} />
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default FloatingCalculator;
