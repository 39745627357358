import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactModal from 'react-modal';
import queryString from 'query-string';
import ReactGA from 'react-ga';

import Callback from './components/elements/Callback';
import RoleAwarePage from './components/elements/RoleAwarePage';
import RoleAwareComponent from './components/elements/RoleAwareComponent';

import Sidebar from './components/structure/Sidebar';
import PreWork from './components/sidebars/PreWork'
import Planning from './components/sidebars/Planning'

import Drawer from './components/structure/Drawer';
import ProgramForm from './components/drawers/ProgramForm';

import Main from './components/structure/Main';

import Programs from './components/mains/Programs';
import ProgramsList from './components/mains/ProgramsList';
import PlanningList from './components/mains/PlanningList';

import './App.css';

ReactModal.setAppElement('#root');
class App extends Component {
   constructor() {
      super();

      this.state = {
         activeDrawer: null,
         activeModal: null,
         refresh: true,
         totalsRefresh: true
      }

      this.expandDrawer = this.expandDrawer.bind(this);
      this.collapseDrawer = this.collapseDrawer.bind(this);
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
      this.handleRefresh = this.handleRefresh.bind(this);
      this.handleTotalsRefresh = this.handleTotalsRefresh.bind(this);
   }

   expandDrawer(drawerContent) {
      this.setState({ activeDrawer: drawerContent });
   }

   collapseDrawer() {
      this.setState({ activeDrawer: null });
   }

   openModal(modalContent) {
      this.setState({ activeModal: modalContent });
   }

   closeModal() {
      this.setState({ activeModal: null });
   }

   handleCancel(e) {
      if (e) e.preventDefault();

      this.setState({
         activeDrawer: null
      });

      this.props.history.push(this.props.location.pathname); // clear any active url params
   }

   componentDidMount() {
      this.props.history.listen((location, action) => {
         this.logPageView()
         // handle program edit route
         const query = queryString.parse(location.search);

         if (query && query.editProgram) {
            this.setState({
               activeDrawer: <ProgramForm id={query.editProgram} handleCancel={this.handleCancel} handleRefresh={this.handleRefresh} />
            });
         }
      });
   }

   handleRefresh() {
      this.setState({ refresh: !this.state.refresh })
   }

   handleTotalsRefresh() {
      this.setState({ totalsRefresh: !this.state.totalsRefresh })
   }

   logPageView() {
      ReactGA.pageview(window.location.pathname + window.location.search);
   }

   componentDidUpdate() {
      let narrow = false;

      if (this.state.activeDrawer) narrow = this.state.activeDrawer.props.narrow;

      if (this.state.activeDrawer && narrow) {
         document.body.classList.remove('drawer-expanded');
         document.body.classList.add('drawer-expanded-narrow');
         document.body.style.overflow = "hidden";
      } else if (this.state.activeDrawer) {
         document.body.classList.remove('drawer-expanded-narrow');
         document.body.classList.add('drawer-expanded');
         document.body.style.overflow = "hidden";
      } else {
         document.body.classList.remove('drawer-expanded', 'drawer-expanded-narrow');
         document.body.style.overflow = "auto";
      }
   }

   render() {
      if (["/cb", "/"].indexOf(this.props.location.pathname) >= 0 || !localStorage.getItem('userToken') || !localStorage.getItem('fiscalYearPlans') || !localStorage.getItem('fiscalYearPlan')) {
          return <Callback />
      } else {
         return (
            <RoleAwarePage access={["admin", "manager", "planner", "observer", "read", "write"]}>
               <div>
                  <ToastContainer />

                  <Sidebar expandDrawer={this.expandDrawer} collapseDrawer={this.collapseDrawer} handleRefresh={this.handleRefresh} handleTotalsRefresh={this.handleTotalsRefresh}>
                     <Switch>
                        <Route path="/pre-work/*">
                           <RoleAwareComponent access={["admin", "manager"]}>
                              <PreWork
                                 openModal={this.openModal}
                                 closeModal={this.closeModal}
                                 expandDrawer={this.expandDrawer}
                                 collapseDrawer={this.collapseDrawer}
                                 handleTotalsRefresh={this.handleTotalsRefresh}
                                 refresh={this.state.refresh}
                                 handleCancel={this.handleCancel}
                                 handleRefresh={this.handleRefresh}
                              />
                           </RoleAwareComponent>
                        </Route>
                        <Route path="/planning">
                           <Planning
                              openModal={this.openModal}
                              closeModal={this.closeModal}
                              expandDrawer={this.expandDrawer}
                              collapseDrawer={this.collapseDrawer}
                              handleCancel={this.handleCancel}
                              handleRefresh={this.handleRefresh}
                              handleTotalsRefresh={this.handleTotalsRefresh}
                              refresh={this.state.refresh}
                              totalsRefresh={this.state.totalsRefresh}
                              />
                        </Route>
                     </Switch>
                  </Sidebar>

                  <div className="wrapper">

                     <Drawer>
                        {this.state.activeDrawer}
                     </Drawer>

                     <Main onCollapse={this.collapseDrawer} handleCancel={this.handleCancel} expandDrawer={this.expandDrawer} handleRefresh={this.handleRefresh} handleTotalsRefresh={this.handleTotalsRefresh}>
                        <Switch onUpdate={this.logPageView}>

                           <Route path="/pre-work/programs">
                              <RoleAwareComponent access={["admin", "manager"]}>
                                 <Programs refresh={this.state.refresh} pageType="programs">
                                    <ProgramsList />
                                 </Programs>
                              </RoleAwareComponent>
                           </Route>

                           <Route path="/planning">
                              <Programs sortBy="prettyName" refresh={this.state.refresh} pageType="planning">
                                 <PlanningList handleTotalsRefresh={this.handleTotalsRefresh} handleRefresh={this.handleRefresh} />
                              </Programs>
                           </Route>
                        </Switch>
                     </Main>

                  </div>

                  <ReactModal
                     isOpen={!!this.state.activeModal}
                     className="react-modal-content"
                     overlayClassName="react-modal-overlay"
                     shouldCloseOnOverlayClick={true}
                     onRequestClose={this.closeModal}>
                     {this.state.activeModal}
                  </ReactModal>
               </div>
            </RoleAwarePage>
         );
      }
   }
}

export default withRouter(App);
