import React from 'react';
import {Checkbox, CheckboxGroup as Checkboxes} from 'react-checkbox-group';
import { authContext } from '../../../adalConfig';
import { toast } from 'react-toastify';

import Form from '../../../components/elements/Form';
import SelectCampaign from '../../../components/elements/SelectCampaign';
import SelectMarket from '../../../components/elements/SelectMarket';

import './SalesforceIntegration.css';

const DEPARTMENTS = [
   {
      value: 'military',
      name: 'Military',
   },
   {
      value: 'division',
      name: 'Local Sampling',
   },
   {
      value: 'brand',
      name: 'Brand',
   },
   {
      value: 'national',
      name: 'National Accounts',
   },
]

const QUARTERS = [
   {
      value: 'Q1',
      name: 'Q1',
   },
   {
      value: 'Q2',
      name: 'Q2',
   },
   {
      value: 'Q3',
      name: 'Q3',
   },
   {
      value: 'Q4',
      name: 'Q4',
   },
]

class SalesforceIntegration extends React.Component {
   constructor(){
      super();
      this.state = {
         departments: DEPARTMENTS,
         departmentValue: [],
         quarters: QUARTERS,
         quarterValue: [],
         campaignId: "",
         marketId: "",
         _isLoading: false
      }
      this.handleChange = this.handleChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handlePost = this.handlePost.bind(this)
   }

   handleChange(target, value) {
      this.setState({ [target]: value })
   }



   handleSubmit(e) {
      e.preventDefault(); 

      const data = {
         quarters: this.state.quarterValue,
         departments: this.state.departmentValue,
         campaignId: this.state.campaignId,
         marketId: this.state.marketId,
      }

      this.handlePost(data);      
   }

   handlePost(data) {
      const token = localStorage.getItem('adal.idtoken');
      if (!token) return;
      
      const FISCAL_YEAR_PLAN_ID = JSON.parse(localStorage.getItem('fiscalYearPlan'))._id;
      this.setState({ _isLoading: true })

      if (data) {
         fetch(process.env.REACT_APP_API_URL + 'salesforce', {
            method: 'post',
            headers: {
               'Authorization': token,
               'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
               'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
         }).then((response) => {
            if (response.status === 498) {
               authContext._renewIdToken((err, token) => {
                  this.handleSubmit()
               })
            } else if (response.ok) {
               return response.json()
            } else {
               return response.json().then(Promise.reject.bind(Promise));
            }
         }).then((json) => {
            if (!json) return;
            toast("Salesforce update succesful", { className: "notification success"});
            this.setState({ _isLoading: false })
            this.props.handleCancel();
         }).catch((error) => {
            toast(error.message, { className: "notification error"});
            this.setState({ _isLoading: false })
         });
      }
   }

   render() {
      return (
         <Form className="form sf">
            <div className="form-header">
               <div>Salesforce Integration</div>
               <div className="buttons">
                  <button className="cancel" onClick={this.props.handleCancel}>Cancel</button>
               </div>
            </div>
            <div className="form-body">
               <div className="form-section">
                  <div className="checkbox-group">
                     <div className="checkbox-group-header">
                        <span className="label">Quarter(s)</span>
                     </div>
                     <div className="checkbox-group-body">
                        <Checkboxes onChange={(value) => this.handleChange('quarterValue', value)} checkboxDepth={2} value={this.state.quarterValue}>
                           {this.state.quarters.map(function(data, i) {
                              return (
                                 <label htmlFor={data.value} className="checkbox-wrapper" key={i}>
                                    <Checkbox id={data.value} value={data.value} />
                                    <span></span>
                                    {data.name}
                                 </label>
                              )
                           })}
                        </Checkboxes>
                     </div>
                  </div>
               </div>
               <div className="form-section">
                  <div className="checkbox-group">
                     <div className="checkbox-group-header">
                        <span className="label">Department(s)</span>
                     </div>
                     <div className="checkbox-group-body">
                        <Checkboxes onChange={(value) => this.handleChange('departmentValue', value)} checkboxDepth={2} value={this.state.departmentValue}>
                           {this.state.departments.map(function(data, i) {
                              return (
                                 <label htmlFor={data.value} className="checkbox-wrapper" key={i}>
                                    <Checkbox id={data.value} value={data.value} />
                                    <span></span>
                                    {data.name}
                                 </label>
                              )
                           })}
                        </Checkboxes>
                     </div>
                  </div>
               </div>
               <div className="form-section">
                  <div className="input-wrapper half">
                     <SelectCampaign label="Campaign" id="campaign" value={this.state.campaignId} target="campaignId" onChange={this.handleChange} />
                  </div>
                  <div className="input-wrapper half">
                     <SelectMarket label="Market" id="market" value={this.state.marketId} target="marketId" onChange={this.handleChange} />
                  </div>
               </div>
               <div className="form-footer">
                  <div className={this.state._isLoading ? "sf-loader is_loading" : "sf-loader"}></div>
                  <button className="button submit" disabled={!this.state.departmentValue.length || !this.state.quarterValue.length || this.state._isLoading} onClick={this.handleSubmit}>Update Salesforce</button>
               </div>
            </div>
         </Form>
      )
   }
}

export default SalesforceIntegration;
