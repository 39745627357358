import React from 'react';
import { toast } from 'react-toastify';
import { authContext } from '../../../adalConfig'
import _displayConfiguration from "../../../json/displayConfiguration.js";

import Pagination from '../../../components/elements/Pagination'

import './Programs.css';
import Filters from "../../elements/Filters/Filters";

class Programs extends React.Component {
   constructor(){
      super();

      this.state = {
         programs: [],
         page: 1,
         limit: 25,
         total: 0,
         pages: 0,
         docs: 0,
         label: "Programs",
         activeSort: "prettyName",
         isLoading: false
      };

      this.onPageUpdate = this.onPageUpdate.bind(this);
      this.onSortUpdate = this.onSortUpdate.bind(this);
      this.getPrograms = this.getPrograms.bind(this);
      this.handleFilterQueryString = this.handleFilterQueryString.bind(this);
   }
   componentDidMount() {
      this.getPrograms()
   }

   componentDidUpdate(nextProps){
      if (nextProps.refresh !== this.props.refresh) {
         this.setState({ page: 1 }, () => {
            this.getPrograms()
         })
      }
   }

   handleFilterQueryString() {
      let filters = localStorage.getItem("filters")
      const fiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
      const productGroup = fiscalYearPlan.productGroupId.name
      let { [productGroup]: { hasDirector } } = _displayConfiguration
      if (filters) {
         filters = JSON.parse(filters)
         let brands = filters.brands && filters.brands.length > 0 ? `&brandId=${filters.brands.join(' ')}` : "";
         let channels = filters.channels && filters.channels.length > 0 ? `&channelId=${filters.channels.join(' ')}` : "";
         let divisions = filters.divisions && filters.divisions.length > 0 ? `&divisionId=${filters.divisions.join(' ')}` : "";
         let directors = filters.directors && filters.directors.length > 0 && hasDirector ? `&directorId=${Filters.generateGroupedDirectorString(filters.directors, filters['joint-directors'])}` : "";
         let markets = filters.markets && filters.markets.length > 0 ? `&marketId=${filters.markets.join(' ')}` : "";
         let programs = filters.programs && filters.programs.length > 0 ? `&campaignId=${filters.programs.join(' ')}` : "";
         let status = filters.status && filters.status.length > 0 && this.props.pageType === "planning" ? `&status=${filters.status.join(' ')}` : "";
         return `${brands}${channels}${divisions}${markets}${programs}${status}${directors}`
      }
      return ""
   }

   handleQueryDepartmentStringString = () => {
      let department = JSON.parse(localStorage.getItem("department"));
      if(department) {
         return `&departmentId=${department.value}`;
      }
      return "";
   }

   getPrograms() {
      const token = localStorage.getItem('adal.idtoken');
      if (!token) return;
      const FISCAL_YEAR_PLAN_ID = JSON.parse(localStorage.getItem('fiscalYearPlan'))._id;
      const fiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
      const productGroup = fiscalYearPlan.productGroupId.name
      let { [productGroup]: { hasDirector } } = _displayConfiguration

      this.setState({ isLoading: true });

      fetch(process.env.REACT_APP_API_URL + `programs?fiscalYearPlanId=${FISCAL_YEAR_PLAN_ID}&page=${this.state.page}` +
          `&limit=${this.state.limit}&populate=marketId:prettyName,directorId:prettyName,channelId:name` +
          `&sort=${this.props.sortBy ? this.props.sortBy : this.state.activeSort}${this.handleFilterQueryString()}` +
          `&hasDirector=${hasDirector}${this.handleQueryDepartmentStringString()}` , {
         method: 'get',
         headers: {
            'Authorization': token,
            'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
            'Content-Type': 'application/json'
         }
      }).then((response) => {
         if (response.ok) {
            return response.json()
         } else {
            return response.json().then(Promise.reject.bind(Promise));
         }
      }).then((json) => {
         this.setState({
            programs: json.docs,
            total: json.total,
            pages: json.pages,
            page: json.page,
            docs: json.docs.length,
            isLoading: false
         });
      }).catch((err) => {
         toast("Error Loading Programs", { className: "notification error"});
         this.setState({ isLoading: false });
      });
   }

   onPageUpdate(data) {
      if (isNaN(data.page)) return;
      if (+data.page > this.state.pages) {
         data.page = this.state.pages
      }
      if (+data.page < 1) {
         data.page = 1
      }
      this.setState({ page: data.page }, () => {
         this.getPrograms()
      })
   }

   onSortUpdate(data) {
      let { activeSort } = this.state

      if (activeSort.includes(data)) {
         this.setState({ activeSort: activeSort.slice(0,1) === "-" ? data : `-${data}`, page: 1 }, () => {
            this.getPrograms()
         })
      } else {
         this.setState({ activeSort: data, page: 1 }, () => {
            this.getPrograms()
         })
      }
   }

   render() {
      const childWithProps = React.Children.map(this.props.children, (child) => {
         return React.cloneElement(child, {activeSort: this.state.activeSort, data: this.state.programs, onUpdate: this.onSortUpdate, updatePrograms: this.getPrograms});
      });

      return (
         <div>
            <Pagination {...this.state} hideButtons={false} onUpdate={this.onPageUpdate} />
            {this.state.isLoading ? null: childWithProps}
            {this.state.isLoading ? null:
               <Pagination {...this.state} hideButtons={true} onUpdate={this.onPageUpdate} />
            }
         </div>
      )
   }
}

export default Programs;
