import React, { Component } from 'react';
import './SelectCampaign.css';

class SelectCampaign extends Component {
   constructor() {
      super();

      this.state = {
         campaigns: []
      }

      this.handleChange = this.handleChange.bind(this);
      this.getDistinctPrograms = this.getDistinctPrograms.bind(this);
   }

   handleChange(e) {
      this.props.onChange(this.props.target, e.target.value);
   }

   componentDidMount() {
      const token = localStorage.getItem('adal.idtoken');
      if (!token) return;
      const { _id: FISCAL_YEAR_PLAN_ID } = JSON.parse(localStorage.getItem('fiscalYearPlan'));
      fetch(process.env.REACT_APP_API_URL + 'programs?fiscalYearPlanId=' + FISCAL_YEAR_PLAN_ID + '&select=prettyName+campaignId&hasDirector=false' , {
         method: 'get',
         headers: {
            'Authorization': token,
            'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
            'Content-Type': 'application/json'
         }
      }).then((response) => {
         return response.json()
      }).then((json) => {
         this.setState({
            campaigns: json && json.docs ? this.getDistinctPrograms(json.docs) : []
         });
      });
   }

   getDistinctPrograms(programs) {
      const campaigns = []
      return programs.reduce((distinctCampaigns, program) => {
         if (!campaigns.includes(program.campaignId)) {
            program._id = program.campaignId
            campaigns.push(program.campaignId)
            distinctCampaigns.push(program)
         }
         return distinctCampaigns
      }, [])
   }

   compare(a, b) {
      if (a.prettyName < b.prettyName) return -1;
      if (a.prettyName > b.prettyName) return 1;
      return 0
   }

   render() {
      return (
         <div>
            {this.props.label &&
               <label className="label" htmlFor={this.props.id}>{this.props.label}</label>
            }
            <div className={this.props.disabled ? "custom-select disabled": "custom-select"}>
               <select id={this.props.id} onChange={this.handleChange} value={this.props.value} disabled={this.props.disabled}>

                  <option default value="">Select a Campaign</option>

                  {this.state.campaigns && this.state.campaigns.sort(this.compare).map(function(brand, i) {
                     return (
                        <option value={brand._id} key={i}>{brand.prettyName}</option>
                     )
                  })}

               </select>
            </div>
         </div>
      )
   }

}
export default SelectCampaign;
