import React, {Component} from 'react'
// import {ArrowBack, ArrowForward} from '@material-ui/icons';
import jwt from "jsonwebtoken";

import "./DepartmentFilter.css";

export default class DepartmentFilter extends Component {
	constructor(props){
		super(props);
		this.state = {
			departments: [],
			currentIndex: undefined
		};
	}

	componentWillMount() {
		const userToken = localStorage.getItem('userToken');
		const user = jwt.decode(userToken, process.env.REACT_APP_ROLE_SECRET);
		this.setDepartmentOptions(user.departments);
	}

	setDepartmentOptions(userDepartments) {
		let departments = [];
		userDepartments.forEach( (department) => {
			departments.push({name: department.prettyName, value: department._id})
		});

		let currentDepartment = localStorage.getItem("department");
		if(currentDepartment) {
			currentDepartment = JSON.parse(currentDepartment);
			departments.map( (department, i) => {
				if(department.value === currentDepartment.value) {
					this.setState({
						departments: departments,
						currentIndex: i
					}, this.props.handleRefresh());
				}
			});
		}
		else if(departments.length >= 1) {
			this.setState({
				departments: departments,
				currentIndex: 0
			}, () => {
				let department = this.state.departments[this.state.currentIndex];
				localStorage.setItem("department", JSON.stringify(department));
				this.props.handleRefresh();
			});
		}

	}

	handleChange(indexChange) {
		let newIndex = this.state.currentIndex + indexChange;
		if(newIndex < 0){
			newIndex = this.state.departments.length-1;
		} else if(newIndex >=this.state.departments.length) {
			newIndex = 0;
		}

		this.setState({currentIndex: newIndex}, () => {
			let department = this.state.departments[newIndex];
			localStorage.setItem("department", JSON.stringify(department));
			this.props.handleRefresh();
		})

	}

	render() {
		return(
			this.state.currentIndex === undefined ? <div/> :
				<div className="department-filter-wrapper">
					<div className="department-filter">
					<span className="left-arrow" onClick={() => this.handleChange(-1)}
						  disabled={this.state.departments.length === 0}><div/></span>
					{/*<ArrowBackIos onClick={() => this.handleChange(-1)}*/}
					{/*			  disabled={this.state.departments.length === 0}/>*/}
					<div className="department-text-container">
						<span>{this.state.departments[this.state.currentIndex].name}</span>
					</div>
					{/*<ArrowForwardIos onClick={this.handleChange(1)}*/}
					{/*			  disabled={this.state.departments.length === 0}/>*/}
					<span className="right-arrow" onClick={() => this.handleChange(1)}
						  disabled={this.state.departments.length === 0}><div/></span>
					</div>
				</div>
		)
	}
}