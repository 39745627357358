import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {authContext} from '../../../adalConfig'
import {toast} from 'react-toastify';
import update from 'immutability-helper';

import Form from '../../../components/elements/Form';
import Input from '../../../components/elements/Input';
import SelectBrand from '../../../components/elements/SelectBrand';
import SelectChannel from '../../../components/elements/SelectChannel';
import CheckboxMonths from '../../../components/elements/CheckboxMonths';

import './ProgramForm.css';
import RoleAwareComponent from '../../elements/RoleAwareComponent';

class ProgramForm extends React.Component {
    constructor() {
        super();
        this.state = {
            prettyName: "",
            marketId: "",
            brandId: "",
            channelId: "",
            campaignId: "",
            activeMonths: [],

            _activeTab: 0,
            _isLoading: false
        };

        this.handlePut = this.handlePut.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClearChanges = this.handleClearChanges.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
    }

    componentDidMount() {
        const token = localStorage.getItem('adal.idtoken');
        if (!token) return;
        const FISCAL_YEAR_PLAN_ID = JSON.parse(localStorage.getItem('fiscalYearPlan'))._id;

        fetch(process.env.REACT_APP_API_URL + `programs/${this.props.id}?populate=marketId:prettyName`, {
            method: 'get',
            headers: {
                'Authorization': token,
                'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(Promise.reject.bind(Promise));
            }
        }).then((json) => {
            this.setState({
                channelId: json.channelId,
                marketId: json.marketId ? json.marketId.prettyName : "",
                brandId: json.brandId,
                campaignId: json.campaignId,
                prettyName: json.prettyName,
                activeMonths: json.monthItems.reduce(ProgramForm.reducer, [])
            });
        }).catch((err) => {
            console.log("err ===> ", err);
        });
    }

    handleValidate() {
        //TODO Might change for proper Validation
        return false;
    }

    handlePut() {
        let hasErrors = this.handleValidate();
        if (!hasErrors) {
            const token = localStorage.getItem('adal.idtoken');
            if (!token) return;

            const activeTab = this.state._activeTab;
            // remove ui properties
            let data = update(this.state, {$unset: ['_activeTab']});

            this.setState({_isLoading: true})

            // put program
            const FISCAL_YEAR_PLAN_ID = JSON.parse(localStorage.getItem('fiscalYearPlan'))._id;
            if (data && activeTab < 2) {
                fetch(process.env.REACT_APP_API_URL + `programs/${this.props.id}`, {
                    method: 'put',
                    headers: {
                        'Authorization': token,
                        'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((response) => {
                    if (response.status === 498) {
                        authContext._renewIdToken((err, token) => {
                            this.handlePut()
                        })
                    } else if (response.ok) {
                        return response.json()
                    } else {
                        return response.json().then(Promise.reject.bind(Promise));
                    }
                }).then((json) => {
                    if (!json) return;
                    toast("Program Edited Successfully", {className: "notification success"});
                    this.setState({_isLoading: false})
                    this.props.handleCancel(); // close drawer on success

                }).catch((error) => {
                    if (error.code && error.code === 11000) {
                        toast("Duplicate name found.", {className: "notification error"});
                    } else {
                        toast(error.message, {className: "notification error"});
                    }
                    this.setState({_isLoading: false})
                });
            }

            //Update for All program in the same campaign
            if (activeTab === 1) {
                let {newPrettyName, newBrandId, newChannelId, newActiveMonths} = this.state;
                data = {};

                if (typeof newActiveMonths !== 'undefined') {
                    data.activeMonths = newActiveMonths;
                }
                if (typeof newPrettyName !== 'undefined') {
                    data.prettyName = newPrettyName;
                }
                if (typeof newBrandId !== 'undefined') {
                    data.brandId = newBrandId;
                }
                if (typeof newChannelId !== 'undefined') {
                    data.channelId = newChannelId;
                }

                if (data === {}) {
                    return this.setState({_isLoading: false});
                }

                fetch(process.env.REACT_APP_API_URL + `campaigns/${this.state.campaignId}?binding=true`, {
                    method: 'put',
                    headers: {
                        'Authorization': token,
                        'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((response) => {
                    if (response.status === 498) {
                        authContext._renewIdToken((err, token) => {
                            this.handlePut()
                        })
                    } else if (response.ok) {
                        return response.json()
                    } else {
                        return response.json().then(Promise.reject.bind(Promise));
                    }
                }).then((json) => {
                    if (!json) return;
                    toast("Campaign Edited Successfully", {className: "notification success"});
                    this.setState({_isLoading: false})
                    this.props.handleRefresh();
                    this.setState(data);
                    this.setState({newActiveMonths: undefined, newPrettyName: undefined, newBrandId: undefined, newChannelId: undefined});
                    this.props.handleCancel(); // close drawer on success
                }).catch((error) => {
                    toast("Campaign Edit Error", {className: "notification error"});
                    this.setState({_isLoading: false})
                });
            }

        }
    }

    static reducer(result, month, i) {
        if (month.active) {
            result.push(i + 1)
        }
        return result
    }

    handleSubmit(e) {
        e.preventDefault();

        this.handlePut();
    }
    handleClearChanges(e) {
        e.preventDefault();
        this.setState({newActiveMonths: undefined, newPrettyName: undefined, newBrandId: undefined, newChannelId: undefined});
    }

    handleChange(target, value) {
        this.setState({
            [target]: value
        });
    }

    render() {
        return (
            <Form className="form">
                <Tabs selectedTabClassName="is-active" selectedIndex={this.state._activeTab}
                      onSelect={_activeTab => this.setState({_activeTab: _activeTab})}>

                    <div className="form-header">

                        <TabList className="tab-links">
                            <Tab className="tab">Edit Program Details</Tab>
                            <RoleAwareComponent access={["admin"]}> <Tab className="tab">Edit Campaign</Tab></RoleAwareComponent>
                        </TabList>

                        <button className="cancel" onClick={this.props.handleCancel}>Cancel</button>

                    </div>

                    <div className="form-body">

                        <TabPanel>

                            <div className="form-section">
                                <div className="input-wrapper half">
                                    <Input label="Campaign Name" id="campaignName" value={this.state.prettyName}
                                           type="text" target="prettyName" onChange={this.handleChange} disabled/>
                                </div>
                                <div className="input-wrapper half">
                                    <Input label="Market" id="market" value={this.state.marketId} type="text"
                                           target="marketId" onChange={this.handleChange} disabled/>
                                </div>
                                <div className="input-wrapper half">
                                    <SelectBrand label="Brand" id="brand" value={this.state.brandId} target="brandId"
                                                 onChange={this.handleChange} disabled/>
                                </div>
                                <div className="input-wrapper half">
                                    <SelectChannel label="Channel" id="channel" value={this.state.channelId}
                                                   target="channelId" onChange={this.handleChange} disabled/>
                                </div>
                            </div>
                            <RoleAwareComponent access={["admin"]}>
                                <div className="form-section">
                                    <CheckboxMonths
                                        label="Months Active"
                                        target="activeMonths"
                                        actualValue={this.state.activeMonths}
                                        value={this.state.activeMonths}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </RoleAwareComponent>

                            <div className="form-footer">
                                <button className="button submit" disabled={this.state._isLoading}
                                        onClick={this.handleSubmit}>Save Changes
                                </button>
                            </div>

                        </TabPanel>

                        <TabPanel>

                            <div className="form-section">

                                <Tabs selectedTabClassName="is-active" selectedIndex={this.state._activeSubTab}>

                                    <div className="form-section">
                                        <div className="input-wrapper half">
                                            <Input label={typeof this.state.newPrettyName !== 'undefined' ? "Campaign Name (new)" : "Campaign Name"} id="campaignName"
                                                   value={typeof this.state.newPrettyName !== 'undefined' ? this.state.newPrettyName : this.state.prettyName}
                                                   type="text" target="newPrettyName" onChange={this.handleChange}/>
                                        </div>
                                        <div className="input-wrapper half">
                                            <SelectBrand label={typeof this.state.newBrandId !== 'undefined' ? "Brand (new)" : "Brand"} id="brand"
                                                         value={typeof this.state.newBrandId !== 'undefined' ? this.state.newBrandId : this.state.brandId}
                                                         target="newBrandId" onChange={this.handleChange}/>
                                        </div>
                                        <div className="input-wrapper half">
                                            <SelectChannel label={typeof this.state.newChannelId !== 'undefined' ? "Channel (new)" : "Channel"} id="channel"
                                                           value={typeof this.state.newChannelId !== 'undefined' ? this.state.newChannelId : this.state.channelId}
                                                           target="newChannelId" onChange={this.handleChange}/>
                                        </div>
                                    </div>

                                    <div className="form-section">
                                        <CheckboxMonths
                                            label={typeof this.state.newActiveMonths !== 'undefined' ? "Months Active (new)" : "Months Active"}
                                            target="newActiveMonths"
                                            actualValue={typeof this.state.newActiveMonths !== 'undefined' ? this.state.newActiveMonths : this.state.activeMonths}
                                            value={typeof this.state.newActiveMonths !== 'undefined' ? this.state.newActiveMonths : this.state.activeMonths}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="form-footer">
                                        <button className="button clear" disabled={this.state._isLoading}
                                                onClick={this.handleClearChanges}>Clear Changes
                                        </button>
                                        <button className="button submit" disabled={this.state._isLoading}
                                                onClick={this.handleSubmit}>Save Changes
                                        </button>
                                    </div>

                                </Tabs>

                            </div>

                        </TabPanel>
                    </div>

                </Tabs>

            </Form>
        )
    }
}

export default ProgramForm;
