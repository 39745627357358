import React from 'react';

import RadioTotals from '../../../components/drawers/RadioTotals';
import _displayConfiguration from '../../../json/displayConfiguration.js';

import './Sidebar.css';

class Sidebar extends React.Component {
    constructor() {
        super();
        this.state = {
            options: [],
            currentOption: "",
            drawerOpen: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    componentWillMount() {
        const fiscalYearPlans = JSON.parse(localStorage.getItem('fiscalYearPlans'));
        const fiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        let readableBrands = fiscalYearPlans.map(fyp => {
            return {value: fyp.productGroupId.name, name: _displayConfiguration[fyp.productGroupId.name].companyName}
        });
        let productGroups = fiscalYearPlans.map((v) => v.productGroupId._id);
        this.setState({
            options: readableBrands.filter((f, i) => productGroups.indexOf(productGroups[i]) === i),
            currentOption: fiscalYearPlan.productGroupId.name
        })
    }

    handleChange(value) {
        let filters = {
            brands: [],
            channels: [],
            divisions: [],
            directors: [],
            markets: [],
            programs: [],
            status: []
        };
        const fiscalYearPlans = JSON.parse(localStorage.getItem('fiscalYearPlans'));
        let fiscalYearPlan = fiscalYearPlans.find(fyp => fyp.productGroupId.name === value);
        this.setState({currentOption: value})
        localStorage.setItem("filters", JSON.stringify(filters))
        localStorage.setItem('fiscalYearPlan', JSON.stringify(fiscalYearPlan));
        this.props.handleRefresh()
        this.props.handleTotalsRefresh()
    }

    handleCancel() {
        this.setState({drawerOpen: false})
        this.props.collapseDrawer()
    }

    handleClick() {
        this.setState({drawerOpen: true})
        this.props.expandDrawer(<RadioTotals data={this.state.options} currentOption={this.state.currentOption}
                                             handleChange={this.handleChange} narrow={"true"} dataType="Company"
                                             handleCancel={this.handleCancel}/>)
    }

    render() {
        const fiscalYearPlans = JSON.parse(localStorage.getItem('fiscalYearPlans'));
        return (
            <section className="sidebar">
                {/*Todo: redirect to environment-specific launchpad.*/}
                <a href="https://launchpad.mktg.run">
                    <header className="sidebar-header">
                        <h1 className="logo">MKTG | Diageo</h1>
                    </header>
                </a>
                {fiscalYearPlans.length > 1 &&
                <div className={this.state.drawerOpen ? "sidebar-select is-active" : "sidebar-select"}
                     onClick={this.handleClick}>
                    <span>{_displayConfiguration[this.state.currentOption].companyName}</span>
                    <span className="select-ellipsis"/>
                </div>
                }

                <div className="sidebar-inner">
                    {this.props.children}
                </div>

            </section>
        )
    }
}

export default Sidebar;
