import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import _displayConfiguration from '../../../json/displayConfiguration.js';
import { authContext } from '../../../adalConfig';
import Filters from '../../../components/elements/Filters';

import './Planning.css';

class Planning extends React.Component {
   constructor(props){
      super(props);
      this.state = {
         options: [],
         currentOption: undefined,
      };

      Planning.addCommas = Planning.addCommas.bind(this)
      this.handleFilterQueryString = this.handleFilterQueryString.bind(this)
      this.getTotals = this.getTotals.bind(this)
   }

   handleFilterQueryString() {
      let filters = localStorage.getItem("filters")
      const fiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
      const productGroup = fiscalYearPlan.productGroupId.name
      let { [productGroup]: { hasDirector } } = _displayConfiguration
      if (filters) {
         filters = JSON.parse(filters)
         let brands = filters.brands && filters.brands.length > 0 ? `&brandId=${filters.brands.join(' ')}` : ""
         let channels = filters.channels && filters.channels.length > 0 ? `&channelId=${filters.channels.join(' ')}` : ""
         let divisions = filters.divisions && filters.divisions.length > 0 ? `&divisionId=${filters.divisions.join(' ')}` : ""
         let status = filters.status && filters.status.length > 0 ? `&status=${filters.status.join(' ')}` : ""
         let markets = filters.markets && filters.markets.length > 0 ? `&marketId=${filters.markets.join(' ')}` : ""
         let programs = filters.programs && filters.programs.length > 0 ? `&campaignId=${filters.programs.join(' ')}` : ""
         let directors = filters.directors && filters.directors.length > 0 && hasDirector ? `&directorId=${Filters.generateGroupedDirectorString(filters.directors, filters['joint-directors'])}` : ""
         return `${brands}${channels}${divisions}${markets}${programs}${status}${directors}`
      }
      return ""
   }

   handleQueryDepartmentStringString = () => {
      let department = JSON.parse(localStorage.getItem("department"));
      if(department) {
         return `&departmentId=${department.value}`;
      }
      return "";
   }

   componentDidUpdate(prevProps) {
      if (prevProps.totalsRefresh !== this.props.totalsRefresh) {
         this.getTotals()
      }
   }

   componentDidMount() {
      this.getTotals()
   }

   getTotals() {
      const token = localStorage.getItem('adal.idtoken');
      if (!token) return;
      const { _id: FISCAL_YEAR_PLAN_ID, productGroupId: { name: productGroup }} = JSON.parse(localStorage.getItem('fiscalYearPlan'));
      let { [productGroup]: { hasDirector } } = _displayConfiguration;

      this.setState({ isLoading: true });

      fetch(process.env.REACT_APP_API_URL + `programs/totals?fiscalYearPlanId=${FISCAL_YEAR_PLAN_ID}` +
          `&planningPeriodIndex=0${this.handleFilterQueryString()}` +
          `${this.handleQueryDepartmentStringString()}`, {
         method: 'get',
         headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
            'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID
         }
      }).then((response) => {
         if (response.status === 498) {
            authContext._renewIdToken((err, token) => {
               this.getTotals()
            })
         } else if (response.ok) {
            return response.json()
         } else {
            return response.json().then(Promise.reject.bind(Promise));
         }
      }).then((json) => {
         json = json[0] || {}
         let totals = {
            events: json.SubmittedEvents || 0,
            cost: json.SubmittedCost || 0,
            kpi: json.SubmittedKPI || 0
         };
         this.setState({totals: totals});

         this.setState({ isLoading: false });
      }).catch((err) => {
         console.log("err ===> ", err)
         toast("Error Loading Totals", { className: "notification error"});
         this.setState({ isLoading: false });
      });
   }

   static addCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   }

   render() {
      return (
         <Fragment>
            <Filters {...this.props} type="planning" />
            {/*{this.state.currentOption ?*/}
               <div className="totals">
                  <p className="totals-title">Totals:</p>
                  <div className="totals-section">
                     <p className="totals-label">Events Submitted:</p>
                     <p className="totals-value">{Planning.addCommas(this.state.totals ? +this.state.totals.events : 0)}</p>
                  </div>
                  <div className="totals-section">
                     <p className="totals-label">Projected Consumer Reach Submitted:</p>
                     <p className="totals-value">{Planning.addCommas(this.state.totals ? +this.state.totals.kpi : 0)}</p>
                  </div>
                  <div className="totals-section">
                     <p className="totals-label">Event Sampling Cost Submitted:</p>
                     <p className="totals-value">${Planning.addCommas(this.state.totals ? +this.state.totals.cost : 0)}</p>
                  </div>

                  {/* <div className="totals-section">
                     <p className="totals-label">Event POS In Progress:</p>
                     <p className="totals-value">${this.addCommas(this.state[this.state.currentOption] ? Math.round(this.state[this.state.currentOption].inProgress.pos) : 0)}</p>
                     <p className="totals-label">Event POS Submitted:</p>
                     <p className="totals-value">${this.addCommas(this.state[this.state.currentOption] ? Math.round(this.state[this.state.currentOption].submitted.pos) : 0)}</p>
                  </div> */}
               </div>
            {/*: <div/>*/}
            {/*}*/}
         </Fragment>
      )
   }
}

export default Planning;
