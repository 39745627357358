import React, { Component } from 'react';
import './SelectBrand.css';

class SelectBrand extends Component {
   constructor() {
      super();

      this.state = {
         brands: []
      }

      this.handleChange = this.handleChange.bind(this);
   }

   handleChange(e) {
      this.props.onChange(this.props.target, e.target.value);
   }

   componentDidMount() {
      const token = localStorage.getItem('adal.idtoken');
      if (!token) return;
      const { _id: FISCAL_YEAR_PLAN_ID, productGroupId: { _id: productGroupId } } = JSON.parse(localStorage.getItem('fiscalYearPlan'));
      fetch(process.env.REACT_APP_API_URL + 'brands?productGroupId=' + productGroupId , {
         method: 'get',
         headers: {
            'Authorization': token,
            'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
            'Content-Type': 'application/json'
         }
      }).then((response) => {
         return response.json()
      }).then((json) => {
         this.setState({
            brands: json && json.docs ? json.docs : []
         });
      });
   }
   compare(a, b) {
      if (a.prettyName < b.prettyName) return -1;
      if (a.prettyName > b.prettyName) return 1;
      return 0
   }

   render() {
      return (
         <div>
            {this.props.label &&
               <label className="label" htmlFor={this.props.id}>{this.props.label}</label>
            }
            <div className={this.props.disabled ? "custom-select disabled": "custom-select"}>
               <select id={this.props.id} onChange={this.handleChange} value={this.props.value} disabled={this.props.disabled}>

                  <option default value="">Select a Brand</option>

                  {this.state.brands && this.state.brands.sort(this.compare).map(function(brand, i) {
                     return (
                        <option value={brand._id} key={i}>{brand.prettyName}</option>
                     )
                  })}

               </select>
            </div>
         </div>
      )
   }

}
export default SelectBrand;
