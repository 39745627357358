import React, { Component } from 'react';
import { authContext } from '../../../adalConfig';
import _displayConfiguration from '../../../json/displayConfiguration.js';

import CheckboxFilters from '../../../components/drawers/CheckboxFilters';

import './Filters.css';

class Filters extends Component {
   constructor(props){
      super(props);
      this.state = {
         brands: null,
         channels: null,
         campaigns: null,
         divisions: null,
         markets: null,
         directors: null,
         status: null,
         brandsText: null,
         channelsText: null,
         campaignsText: null,
         divisionsText: null,
         marketsText: null,
         directorsText: null,
         statusText: null
      };
      this.handleClear = this.handleClear.bind(this)
      this.updateButton = this.updateButton.bind(this)
      this.getFilters = this.getFilters.bind(this)
   }

   componentDidMount() {
      this.getFilters();
   }

   componentDidUpdate(prevProps) {
      if (prevProps.refresh !== this.props.refresh) {
         this.getFilters()
      }
   }

   static handleQueryString() {
      let qs = "";
      let filters = localStorage.getItem("filters");
      if (filters) {
         filters = JSON.parse(filters);
         let brands = filters.brands && filters.brands.length > 0 ? `&brandId=${filters.brands.join(' ')}` : "";
         let channels = filters.channels && filters.channels.length > 0 ? `&channelId=${filters.channels.join(' ')}` : "";
         let divisions = filters.divisions && filters.divisions.length > 0 ? `&divisionId=${filters.divisions.join(' ')}` : "";
         let markets = filters.markets && filters.markets.length > 0 ? `&marketId=${filters.markets.join(' ')}` : "";
         let programs = filters.programs && filters.programs.length > 0 ? `&campaignId=${filters.programs.join(' ')}` : "";
         let directors = filters.directors && filters.directors.length > 0 ? `&directorId=${Filters.generateGroupedDirectorString(filters.directors, filters['joint-directors'])}` : "";
         qs = `${brands}${channels}${divisions}${markets}${programs}${directors}`
      }
      return qs || ""
   }

   static generateGroupedDirectorString(directors, groups){
      let allDirectors = [];
      directors.forEach((director)=>{
         allDirectors.push(director);
         if (groups.hasOwnProperty(director)){
            allDirectors = allDirectors.concat(groups[director]);
         }
      });
      return allDirectors.join(' ');
   }

   getFilters() {
      const token = localStorage.getItem('adal.idtoken');
      if (!token) return;
      const { _id: FISCAL_YEAR_PLAN_ID, productGroupId: { name: productGroup }} = JSON.parse(localStorage.getItem('fiscalYearPlan'));
      let { [productGroup]: { hasDirector } } = _displayConfiguration
      fetch(process.env.REACT_APP_API_URL + `filter/${FISCAL_YEAR_PLAN_ID}/${this.props.type === "planning" ? "planning" : this.props.type === "permissions" ? "permissions" : "pre-work"}?hasDirector=${hasDirector}${Filters.handleQueryString()}` , {
         method: 'get',
         headers: {
            'Authorization': token,
            'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
            'Content-Type': 'application/json'
         }
      }).then((response) => {
         if (response.status === 498) {
            authContext._renewIdToken((err, token) => {
               this.getFilters()
            })
         } else if (response.ok) {
            return response.json()
         } else {
            return response.json().then(Promise.reject.bind(Promise));
         }
      }).then((json) => {
         this.setState({
            brands: json.brands,
            channels: json.channels,
            campaigns: json.campaigns,
            divisions: json.divisions,
            directors: json.directors,
            markets: json.markets,
            status: Filters.handleStatusOptions()
         }, () => {
            this.updateButton()
         });
      }).catch((err) => {
         console.log("err ===> ", err);
      });
   }

   static handleStatusOptions() {
      return [
         {
            prettyName: "Plan Not Started",
            _id: "NotStarted"
         },
         {
            prettyName: "Plan Submitted",
            _id: "Submitted"
         }
      ];
   }

   handleClear() {
      let filters = {
         brands: [],
         channels: [],
         divisions: [],
         directors: [],
         markets: [],
         programs: [],
         status: []
      };
      localStorage.setItem("filters", JSON.stringify(filters))
      this.props.collapseDrawer();
      this.props.handleRefresh();
      this.props.handleTotalsRefresh();
      this.getFilters();
      this.updateButton();
   }

   static _mapArrayToIdPrettyNameObject(array) {
      if (!array) return {};
      return array.reduce((result, element) => {
         result[element._id] = element.prettyName
         return result
      }, {})
   }

   updateButton() {
      let filters = localStorage.getItem("filters");
      let brands, channels, divisions, markets, programs, status, directors;
      if (filters) {
         filters = JSON.parse(filters);
         let brandsMap = Filters._mapArrayToIdPrettyNameObject(this.state.brands);
         let channelsMap = Filters._mapArrayToIdPrettyNameObject(this.state.channels);
         let divisionsMap = Filters._mapArrayToIdPrettyNameObject(this.state.divisions);
         let directorsMap = Filters._mapArrayToIdPrettyNameObject(this.state.directors);
         let marketsMap = Filters._mapArrayToIdPrettyNameObject(this.state.markets);
         let programsMap = Filters._mapArrayToIdPrettyNameObject(this.state.campaigns);
         let statusMap = Filters._mapArrayToIdPrettyNameObject(this.state.status);
         if (filters.brands && filters.brands.length > 0) {
            brands = filters.brands
               .filter(brand => brandsMap[brand])
               .map(brand => brandsMap[brand])
               .join(", ")
         }
         if (filters.channels && filters.channels.length > 0) {
            channels = filters.channels
               .filter(channel => channelsMap[channel])
               .map(channel => channelsMap[channel])
               .join(", ")
         }
         if (filters.divisions && filters.divisions.length > 0) {
            divisions = filters.divisions
               .filter(division => divisionsMap[division])
               .map(division => divisionsMap[division])
               .join(", ")
         }
         if (filters.directors && filters.directors.length > 0) {
            directors = filters.directors
               .filter(director => directorsMap[director])
               .map(director => directorsMap[director])
               .join(", ")
         }
         if (filters.markets && filters.markets.length > 0) {
            markets = filters.markets
               .filter(market => marketsMap[market])
               .map(market => marketsMap[market])
               .join(", ")
         }
         if (filters.programs && filters.programs.length > 0) {
            programs = filters.programs
               .filter(program => programsMap[program])
               .map(program => programsMap[program])
               .join(", ")
         }
         if (filters.status && filters.status.length > 0) {
            status = filters.status
               .filter(statusEl => statusMap[statusEl])
               .map(statusEl => statusMap[statusEl])
               .join(", ")
         }
      }
         this.setState({ brandsText: brands, channelsText: channels, campaignsText: programs, divisionsText: divisions, directorsText: directors, marketsText: markets, statusText: status})
   }

   render() {
      const fiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
      let filterRules = false;
      if (typeof fiscalYearPlan.enabledFilters !== 'undefined'){
         filterRules = true;
      }
      const productGroup = fiscalYearPlan.productGroupId.name;

      return (
         <ul className={this.props.type !== "planning" ? "filters no-bg" : "filters"}>
            {["programs", "planning"].includes(this.props.type) &&
            (!filterRules || fiscalYearPlan.enabledFilters.divisions) &&
            <li onClick={() => this.props.expandDrawer(<CheckboxFilters
               data={this.state.divisions}
               label={"Region"}
               labelPlural="s"
               target={"divisions"}
               alphabetize={true}
               updateButton={this.updateButton}
               handleRefresh={this.props.handleRefresh}
               handleTotalsRefresh={this.props.handleTotalsRefresh}
               getFilters={this.getFilters}
               handleCancel={this.props.handleCancel}
               narrow={true} />)}
               >
                  <a className={this.state.divisionsText ? "is-active": ""}>
                     {this.state.divisionsText || "No " + _displayConfiguration[productGroup].displayRoleText("Region") + " Selected"}
                  </a>
                  <span className="filter-ellipsis"/>
               </li>
            }
            {["programs", "planning"].includes(this.props.type) &&
            (!filterRules || fiscalYearPlan.enabledFilters.markets) &&
            <li onClick={() => this.props.expandDrawer(<CheckboxFilters
               data={this.state.markets}
               label={"Market"}
               labelPlural="s"
               target={"markets"}
               alphabetize={true}
               updateButton={this.updateButton}
               handleRefresh={this.props.handleRefresh}
               handleTotalsRefresh={this.props.handleTotalsRefresh}
               getFilters={this.getFilters}
               handleCancel={this.props.handleCancel}
               narrow={true} />)}
               >
                  <a className={this.state.marketsText ? "is-active": ""}>
                     {this.state.marketsText || "No Markets Selected"}
                  </a>
                  <span className="filter-ellipsis"/>
               </li>
            }
            {["programs", "planning"].includes(this.props.type) &&
            (!filterRules || fiscalYearPlan.enabledFilters.channels) &&
            <li onClick={() => this.props.expandDrawer(<CheckboxFilters
               data={this.state.channels}
               label={"Channel"}
               labelPlural="s"
               target={"channels"}
               alphabetize={true}
               updateButton={this.updateButton}
               handleRefresh={this.props.handleRefresh}
               handleTotalsRefresh={this.props.handleTotalsRefresh}
               getFilters={this.getFilters}
               handleCancel={this.props.handleCancel}
               narrow={true} />)}
               >
                  <a className={this.state.channelsText ? "is-active": ""}>
                     {this.state.channelsText || "No Channels Selected"}
                  </a>
                  <span className="filter-ellipsis"/>
               </li>
            }
            {["programs", "planning"].includes(this.props.type) &&
            (!filterRules || fiscalYearPlan.enabledFilters.brands) &&
            <li onClick={() => this.props.expandDrawer(<CheckboxFilters
               data={this.state.brands}
               label={"Brand"}
               labelPlural="s"
               target={"brands"}
               alphabetize={true}
               updateButton={this.updateButton}
               handleRefresh={this.props.handleRefresh}
               handleTotalsRefresh={this.props.handleTotalsRefresh}
               getFilters={this.getFilters}
               handleCancel={this.props.handleCancel}
               narrow={true} />)}
               >
                  <a className={this.state.brandsText ? "is-active": ""}>
                     {this.state.brandsText || "No Brands Selected"}
                  </a>
                  <span className="filter-ellipsis"/>
               </li>
            }
            {["programs", "planning"].includes(this.props.type) &&
            (!filterRules || fiscalYearPlan.enabledFilters.programs) &&
            <li onClick={() => this.props.expandDrawer(<CheckboxFilters
               data={this.state.campaigns}
               label={"Program"}
               labelPlural="s"
               target={"programs"}
               alphabetize={true}
               updateButton={this.updateButton}
               handleRefresh={this.props.handleRefresh}
               handleTotalsRefresh={this.props.handleTotalsRefresh}
               getFilters={this.getFilters}
               handleCancel={this.props.handleCancel}
               narrow={true} />)}
               >
                  <a className={this.state.campaignsText ? "is-active": ""}>
                     {this.state.campaignsText || "No Programs Selected"}
                  </a>
                  <span className="filter-ellipsis"/>
               </li>
            }
            {["programs", "planning"].includes(this.props.type) &&
            _displayConfiguration[productGroup].showDirectors &&
            (!filterRules || fiscalYearPlan.enabledFilters.managers) &&
            <li onClick={() => this.props.expandDrawer(<CheckboxFilters
               data={this.state.directors}
               label={"Director"}
               labelPlural="s"
               target={"directors"}
               alphabetize={true}
               updateButton={this.updateButton}
               handleRefresh={this.props.handleRefresh}
               handleTotalsRefresh={this.props.handleTotalsRefresh}
               getFilters={this.getFilters}
               handleCancel={this.props.handleCancel}
               narrow={true} />)}
               >
                  <a className={this.state.directorsText ? "is-active": ""}>
                     {this.state.directorsText || "No Directors Selected"}
                  </a>
                  <span className="filter-ellipsis"/>
               </li>
            }
            {["planning"].includes(this.props.type) &&
            (!filterRules || fiscalYearPlan.enabledFilters.status) &&
            <li onClick={() => this.props.expandDrawer(<CheckboxFilters
               data={this.state.status}
               label={"Status"}
               labelPlural="es"
               target={"status"}
               alphabetize={false}
               updateButton={this.updateButton}
               handleRefresh={this.props.handleRefresh}
               handleTotalsRefresh={this.props.handleTotalsRefresh}
               getFilters={this.getFilters}
               handleCancel={this.props.handleCancel}
               narrow={true} />)}
               >
                  <a className={this.state.statusText ? "is-active": ""}>
                     {this.state.statusText || "No Status Selected"}
                  </a>
                  <span className="filter-ellipsis"/>
               </li>
            }
            {/*{["permissions"].includes(this.props.type) &&*/}
            {/*<li onClick={() => this.props.expandDrawer(<CheckboxFilters*/}
            {/*   data={this.state.roles}*/}
            {/*   label={"Role"}*/}
            {/*   labelPlural="s"*/}
            {/*   target={"roles"}*/}
            {/*   alphabetize={false}*/}
            {/*   updateButton={this.updateButton}*/}
            {/*   handleRefresh={this.props.handleRefresh}*/}
            {/*   handleTotalsRefresh={this.props.handleTotalsRefresh}*/}
            {/*   getFilters={this.getFilters}*/}
            {/*   handleCancel={this.props.handleCancel}*/}
            {/*   narrow={true} />)}*/}
            {/*   >*/}
            {/*      <a className={this.state.rolesText ? "is-active": ""}>*/}
            {/*         {this.state.rolesText || "No Role Selected"}*/}
            {/*      </a>*/}
            {/*      <span className="filter-ellipsis"></span>*/}
            {/*   </li>*/}
            {/*}*/}
            {["programs", "planning"].includes(this.props.type) &&
               <li>
                  <a onClick={this.handleClear}>Clear All</a>
               </li>
            }
            </ul>
         )
      }

   }
   export default Filters;
